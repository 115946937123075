import { ReactNode } from "react";
import { toast as ReactToast } from "react-toastify";

const toast = {
  success: (msg: string | ReactNode, id: any = undefined) => {
    if (id !== undefined) {
      return ReactToast.update(id, {
        render: (
          <>
            <div className="flex items-center justify-start custom-toast">
              <img
                src="/assets/images/toast-success-check.svg"
                className="p-2 rounded-3"
              />
              {msg}
            </div>
          </>
        ),
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    return ReactToast.success(
      <div className="flex items-center custom-toast">
        <img
          src="/assets/images/toast-success-check.svg"
          className="me-4  p-2 rounded-3"
        />
        {msg}
      </div>
    );
  },
  error: (msg: string | ReactNode, id: any = undefined) => {
    if (id !== undefined) {
      return ReactToast.update(id, {
        render: (
          <>
            <div className="flex items-center custom-toast">
              <img
                src="/assets/images/close-icon.svg"
                className="me-4  p-2 rounded-3"
              />
              {msg}
            </div>
          </>
        ),
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    return ReactToast.error(
      <div className="flex items-center custom-toast">
        <img
          src="/assets/images/close-icon.svg"
          className="me-4  p-2 rounded-3"
        />
        {msg}
      </div>
    );
  },
  loading: (msg: string | ReactNode, id: any = undefined) => {
    if (id !== undefined) {
      return ReactToast.update(id, {
        render: msg,
        type: "info",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    return ReactToast.loading(
      <div className="flex items-center custom-toast">
        <div className="spinner-border me-4 custom-progress-bar" role="status">
          {/* Overwrite the progress bar class directly */}
          <span className="visually-hidden"></span>
        </div>
        {msg}
      </div>,
      {
        closeButton: true,
        closeOnClick: true,
      }
    );
  },
};

export { toast as toastHelper };
export default toast;
