import React, { Component } from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  CartesianGrid,
} from "recharts";
import { getAdminAnalytics } from "../../../api/rest-apis";
import DateRangePicker from "./date-range-picker";
import { saveAs } from "file-saver";

const numberFormatter = (value: any) => {
  return value.toLocaleString(); // Adds commas to the numbers (e.g., 1,000,000)
};

interface CharacterAnalyticsDashboardProps {}

interface CharacterAnalyticsDashboardState {
  characterData: {
    character_name: string;
    assistants: { for_plans: string[]; total_token_used: number }[];
  }[];
  selectedFilter: string;
  startDate: Date | null;
  endDate: Date | null;
}

class CharacterAnalyticsDashboard extends Component<
  CharacterAnalyticsDashboardProps,
  CharacterAnalyticsDashboardState
> {
  constructor(props: CharacterAnalyticsDashboardProps) {
    super(props);

    this.state = {
      characterData: [],
      selectedFilter: "All the time",
      startDate: null,
      endDate: null,
    };
  }

  async componentDidMount() {
    this.getTheData();
  }

  async getTheData(
    filter: string = this.state.selectedFilter,
    customRange: { start: Date | null; end: Date | null } = {
      start: null,
      end: null,
    }
  ) {
    try {
      let response: any;
      if (customRange.start && customRange.end) {
        response = await getAdminAnalytics({
          timeRange: this.state.selectedFilter,
          customRange,
        });
      } else {
        response = await getAdminAnalytics({
          timeRange: filter,
        });
      }

      // Example response format adjustment
      const transformedData = response.map((character: any) => {
        const planCombination = character.assistants
          .map((assistant: any) => assistant.for_plans.sort().join(" & "))
          .join("; ");
        const totalTokenUsed = character.assistants.reduce(
          (acc: number, assistant: any) => acc + assistant.total_token_used,
          0
        );
        return {
          character_name: character.character_name,
          ...character.assistants.reduce((acc: any, assistant: any) => {
            const key = assistant.for_plans.sort().join(" & ");
            acc[key] = (acc[key] || 0) + assistant.total_token_used;
            return acc;
          }, {}),
          // totalTokenUsed,
          // plans: planCombination,
        };
      });

      console.log(transformedData);

      this.setState({ characterData: transformedData });
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  handleFilterChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filter: string = event.target.value;
    this.setState({ selectedFilter: filter });
    await this.getTheData(filter);
  };

  handleUpdateCustomDate = async (state: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    this.setState({
      startDate: state.startDate,
      endDate: state.endDate,
    });

    await this.getTheData(this.state.selectedFilter, {
      start: state.startDate,
      end: state.endDate,
    });
  };

  exportToCSV(data: any[] = this.state.characterData) {
    const csvData = this.convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Overall analytics.csv");
  }

  convertToCSV(data: any[]): string {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((item) => Object.values(item).join(","));
    return header + "\n" + rows.join("\n");
  }

  render() {
    // Find all unique plan combinations across all characters and convert to array for compatibility
    let uniquePlans = Array.from(
      new Set(
        this.state.characterData.flatMap((character) =>
          Object.keys(character).filter(
            (key) =>
              key !== "character_name" &&
              key !== "totalTokenUsed" &&
              key !== "plans"
          )
        )
      )
    );

    const colors = [
      "#3498db", // Soft Blue
      "#f1c40f", // Sun Flower
      "#2ecc71", // Emerald
      "#9b59b6", // Amethyst
      "#e74c3c", // Alizarin
      "#e67e22", // Carrot
      "#1abc9c", // Turquoise
      "#2c3e50", // Midnight Blue
      "#7f8c8d", // Concrete
      "#8e44ad", // Wisteria
    ];

    // // Function to shuffle an array
    // function shuffleArray(array: any) {
    //   for (let i = array.length - 1; i > 0; i--) {
    //     const j = Math.floor(Math.random() * (i + 1));
    //     [array[i], array[j]] = [array[j], array[i]];
    //   }
    //   return array;
    // }

    // const colorChoices: string[] = [];
    // const shuffledColors = shuffleArray([...colors]); // Create a shuffled version of the colors array

    // uniquePlans.forEach((plan, index) => {
    //   if (index >= shuffledColors.length) {
    //     // If the index exceeds the length of the colors array, start reusing colors
    //     colorChoices.push(shuffledColors[index % shuffledColors.length]);
    //   } else {
    //     colorChoices.push(shuffledColors[index]);
    //   }
    // });

    function capitalizeWords(str: string) {
      let myStr =
        str === "plus" ? "patron" : str === "basic" ? "subscriber" : str;

      return myStr
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }

    // uniquePlans = uniquePlans.map((plan: string) => capitalizeWords(plan));

    return (
      <div className="character-analytics-dashboard my-6">
        <div className="title">Character Analytics Dashboard</div>
        <p className="mb-8">
          The <strong>Y</strong> axis indicates the number of tokens, and the
          <strong> X</strong> axis indicates different personality modes, each
          personality mode differentiated by various colors.
        </p>

        <div className="bookish-input-group my-4">
          <div className="sm:col-span-3">
            <p className="bookish-input-label">
              Filter <span className="required">*</span>
            </p>
            <div className="mt-2">
              <select
                id="filter"
                className="bookish-dropdown-field"
                name="filter"
                value={this.state.selectedFilter} // Bind select value to state
                onChange={this.handleFilterChange} // Update state on change
              >
                {[
                  "Today",
                  "Yesterday",
                  "Last 7 days",
                  "Last 30 days",
                  "This Month",
                  "Last Month",
                  "Last 12 months",
                  "This Year",
                  "Last Year",
                  "All the time",
                ].map((value: string, index: number) => (
                  <option key={index}>{value}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="relative">
            <button
              style={{
                background: "#8884D8",
                color: "#fff",
                fontWeight: 600,
                padding: ".5rem",
                borderRadius: 30,
                position: "absolute",
                right: 0,
              }}
              onClick={() => this.exportToCSV()}
            >
              Export
            </button>
          </div>

          <div className="sm:col-span-3">
            <p className="bookish-input-label">Custom</p>
            <DateRangePicker
              callback={(state: any) => this.handleUpdateCustomDate(state)}
            ></DateRangePicker>
          </div>
        </div>

        <BarChart
          width={1200}
          height={700}
          data={this.state.characterData}
          margin={{ top: 20, right: 50, left: 100, bottom: 20 }}
        >
          <XAxis dataKey="character_name" />
          <YAxis tickFormatter={numberFormatter} />
          <Tooltip formatter={(value) => value.toLocaleString()} />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          {uniquePlans.map((plan, index) => (
            <Bar
              key={index}
              dataKey={plan}
              fill={colors[index]}
              name={capitalizeWords(plan).replace(",", ", ")}
            />
          ))}
        </BarChart>
      </div>
    );
  }
}

export default CharacterAnalyticsDashboard;
