import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

type State = {
  startDate: Date | null;
  endDate: Date | null;
};

class DateRangePicker extends React.Component<{ callback: any }, State> {
  constructor(props: { callback: any }) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    };
  }

  handleStartDateChange = (date: Date | null) => {
    console.log(date ? new Date(date) : null);
    this.setState({ startDate: date });
    // Assuming `date` is already a Date object. If it's a string, convert it with new Date(dateString).
    const formattedStartDate = date ? new Date(date).toISOString() : null;
    const formattedEndDate = this.state.endDate
      ? new Date(this.state.endDate).toISOString()
      : null;
    this.props.callback({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  handleEndDateChange = (date: Date | null) => {
    console.log(date);
    this.setState({ endDate: date });
    const formattedStartDate = this.state.startDate
      ? new Date(this.state.startDate).toISOString()
      : null;
    const formattedEndDate = date ? new Date(date).toISOString() : null;

    this.props.callback({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label="Start Date"
            value={this.state.startDate}
            onChange={(newValue: any) => this.handleStartDateChange(newValue)}
          />

          <DatePicker
            label="End Date"
            value={this.state.endDate}
            onChange={(newValue: any) => this.handleEndDateChange(newValue)}
          />
        </DemoContainer>
      </LocalizationProvider>
    );
  }
}

export default DateRangePicker;
