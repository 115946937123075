import React from "react";
import { Link } from "react-router-dom";
import { activateTrailSubscription } from "../api/rest-apis";
import { toastHelper } from "../helpers/toast";

interface PopupProps {
  open: boolean;
  setOpen: any;
  reasonForPopUp: string;
  replace: string;
}

interface PopupState {
  shouldRender: boolean;
}

class Popup extends React.Component<PopupProps, PopupState> {
  constructor(props: PopupProps) {
    super(props);
    this.state = {
      shouldRender: true,
    };
  }

  messages: any = {
    monthly_limit: `You have reached your monthly ChatPoints limit. Consider trying a free trial with the Plus plan and get the full experience. Click <a href="/price" style="color: #0070E0">here</a> to check out our plans.`,

    plus_trail_ended: `You have reached your free trial ChatPoints limit. Click ‘Activate’ to start the subscription now.`,

    type: "starting_long_conversation",
    starting_long_conversation: `Your conversation is starting to get long. The longer your conversation, the more ChatPoints you use with every message. Consider starting a new conversation to conserve your ChatPoints.`,

    exhausted_current_subscription: `You have exhausted the ChatPoints for your current subscription plan. Get ChatPoint Boosters to continue your conversation. You can stock up because unused ChatPoint Boosters will carry over month to month. Click <a href="/price?chatpoint-booster=true" style="color: #0070E0">here</a> to get ChatPoint Boosters now.`,

    assistant_id_confilct: `This conversation is only accessible in {{replace}}. Consider starting a new conversation.`,
  };

  setOpen = (open: boolean) => {
    if (open) {
      this.setState({ shouldRender: true }, () => {
        // Ensure the component is in the DOM before starting the animation
        setTimeout(() => this.props.setOpen(true), 10);
      });
    } else {
      this.props.setOpen(false);
      // Wait for the animation to finish before removing the modal from the DOM
      setTimeout(() => this.setState({ shouldRender: false }), 300);
    }
  };

  async activatePlusTrail() {
    const id = toastHelper.loading("Activating ...");
    try {
      await activateTrailSubscription();
      toastHelper.success("Subscription Activated!", id);
    } catch (error) {
      toastHelper.error(
        "Subscription couldn't be activated. Contact support.",
        id
      );
    }
  }

  renderModal() {
    const { open } = this.props;
    return (
      <div
        className={`fixed inset-0 px-2 z-10 overflow-hidden flex items-center justify-center transition-opacity duration-300 ${
          open ? "opacity-100" : "opacity-0"
        }`}
        onClick={() => this.props.setOpen(false)}
      >
        <div className="absolute inset-0 bg-gray-500 bg-opacity-75"></div>
        {/* Modal Content */}
        <div
          className={`bg-white rounded-md shadow-xl overflow-hidden max-w-md w-full sm:w-96 md:w-1/2 lg:w-2/3 xl:w-1/3 z-50 transition-all duration-300 ${
            open ? "scale-100" : "scale-75"
          }`}
          onClick={(e) => e.stopPropagation()} // Prevent click from closing modal
        >
          {/* Modal Header */}
          <div className=" text-white px-4 py-2 flex justify-between items-center bg-primary">
            <h2 className="text-lg font-semibold text-white">Important!</h2>
          </div>
          {/* Modal Body */}
          <div className="p-4">
            <p
              dangerouslySetInnerHTML={{
                __html:
                  this.props.reasonForPopUp === "assistant_id_confilct"
                    ? this.messages[this.props.reasonForPopUp].replace(
                        "{{replace}}",
                        this.props.replace
                      )
                    : this.messages[this.props.reasonForPopUp],
              }}
            ></p>
          </div>
          {/* Modal Footer */}
          {this.props.reasonForPopUp === "exhausted_current_subscription" && (
            <div className="flex justify-end border-t p-4">
              <Link
                to="/price?chatpoint-booster=true"
                onClick={() => this.setOpen(false)}
                className="px-3 py-1 bg-primary text-white rounded-md"
              >
                Go to ChatPoint boosters
              </Link>
            </div>
          )}
          {this.props.reasonForPopUp === "monthly_limit" && (
            <div className="flex justify-end border-t p-4">
              <Link
                to="/price"
                onClick={() => this.setOpen(false)}
                className="px-3 py-1 bg-primary text-white rounded-md"
              >
                See plans
              </Link>
            </div>
          )}

          {this.props.reasonForPopUp === "plus_trail_ended" && (
            <div className="flex justify-end border-t p-4">
              <button
                onClick={() => {
                  this.setOpen(false);
                  this.activatePlusTrail();
                }}
                className="px-3 py-1 bg-primary text-white rounded-md"
              >
                Activate
              </button>
            </div>
          )}
          {/* Close Modal */}
          <button
            onClick={() => this.setOpen(false)}
            className="absolute -top-0 -right-0 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white z-10"
          >
            ✗
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { shouldRender } = this.state;
    return (
      <div className="flex justify-center items-center h-screen">
        {shouldRender && this.renderModal()}
      </div>
    );
  }
}

export default Popup;
