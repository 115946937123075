import React from "react";
import { getCharacterById, putCharacter } from "../../../api/rest-apis";
import toast from "../../../helpers/toast";
import withRouter from "../../../helpers/withRouter";
import { Character } from "../../../interfaces/rest-api";
import CharacterForm from "../components/character-form";
import AdminHeader from "../components/header";

interface EditCharacterProps {
  id: string;
}

interface EditCharacterState {
  character: Character | null;
  loading: boolean;
}

class EditCharacter extends React.Component<
  EditCharacterProps,
  EditCharacterState
> {
  state = {
    loading: true,
    character: null,
  };
  constructor(props: EditCharacterProps) {
    super(props);
    this.getCharacter();
  }

  async getCharacter() {
    try {
      const response: any = await getCharacterById(this.props.id);
      this.setState({ character: response.characters[0], loading: false });
      setTimeout(() => console.log(this.state.character), 3000);
    } catch (error) {}
  }

  putCharacterAPICall = async (form: Character) => {
    const loading = toast.loading("Updating Character...");
    try {
      if (this.state.character) {
        const response = await putCharacter(this.props.id, {
          ...form,
        });

        toast.success("Character Updated!", loading);

        return response;
      }
    } catch (error) {
      console.log(error);
      toast.error("Couldn't update character", loading);
    }
  };

  render() {
    return (
      <div className="admin">
        <AdminHeader></AdminHeader>
        <div className="edit-character">
          {!this.state.loading && (
            <CharacterForm
              create={false}
              reverseFunction={this.putCharacterAPICall}
              payload={this.state.character}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(EditCharacter);
