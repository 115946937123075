import React from "react";
import { deleteSave, getSave } from "../../api/rest-apis";
import Header from "../../components/header";
import SideMenu from "../../components/sideMenu";
import { toastHelper } from "../../helpers/toast";
import withRouter from "../../helpers/withRouter";
import DeleteModalHelper from "../../helpers/delete-modal";
import Spin from "../../helpers/spin";

interface FavouriteProps {
  navigate: any;
}

interface FavouriteState {
  saves: any[];
  deleteSavedId: string;
  showDeleteModal: boolean;
  loading: boolean;
}

class Favourite extends React.Component<FavouriteProps, FavouriteState> {
  state = {
    saves: [],
    deleteSavedId: "",
    showDeleteModal: false,
    loading: true,
  };
  constructor(props: FavouriteProps) {
    super(props);
    this.getSaves();
  }

  async getSaves() {
    try {
      const response: any = await getSave();
      this.setState({ saves: response.saves.reverse(), loading: false });
      console.log(JSON.stringify(response));
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  async deleteSave() {
    const id = toastHelper.loading("Deleting ...");
    try {
      await deleteSave(this.state.deleteSavedId);

      toastHelper.success("Conversation have been deleted!", id);
      this.setState({ showDeleteModal: false, deleteSavedId: "" });
      setTimeout(async () => await this.getSaves(), 500);
    } catch (error) {
      toastHelper.error("Error on deleting conversation", id);
      this.setState({ showDeleteModal: false, deleteSavedId: "" });
    }
  }

  render() {
    return (
      <>
        <div className="home-container">
          <SideMenu></SideMenu>
          <div className="main-view lg:ml-6">
            <Header></Header>

            <div className="favourites mt-8">
              <p className="favourites-title">Saved Conversations</p>
              {this.state.loading && (
                <div className="flex justify-center mt-8">
                  <Spin></Spin>
                </div>
              )}
              {this.state.saves.length == 0 && !this.state.loading && (
                <p style={{ color: "var(--primary-color)" }}>
                  It seems like we haven't found any chats you've saved.
                </p>
              )}
              {this.state.saves.map((save: any, index) => (
                <div className="favourite-card flex">
                  <img
                    className="character-img"
                    src={save.character_profile}
                    alt=""
                    onClick={() =>
                      this.props.navigate(
                        `/chat/${save.character_id}/c/${save.conversation_id}`
                      )
                    }
                  />
                  <div
                    className="flex flex-col justify-center"
                    onClick={() =>
                      this.props.navigate(
                        `/chat/${save.character_id}/c/${save.conversation_id}`
                      )
                    }
                  >
                    <p className="favourite-title ml-3">
                      {save.title.length > 32
                        ? save.title.slice(0, 32)
                        : save.title}
                    </p>
                    <p className="favourite-character ml-3">
                      {"  (" + save.character_name + ")"}
                    </p>

                    <p className="favourite-italic ml-3">
                      Last conversation:{" "}
                      {new Date(save.last_conversation_at).toLocaleDateString()}
                      .
                    </p>
                  </div>
                  <div
                    className="ml-auto flex align-center"
                    onClick={() =>
                      this.setState({
                        showDeleteModal: true,
                        deleteSavedId: save._id,
                      })
                    }
                  >
                    <img src="/assets/images/delete-red.svg" alt="" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {this.state.showDeleteModal && (
          <>
            <DeleteModalHelper
              title={"Delete Conversation"}
              message={"Are you sure you want to delete the conversation ? "}
              close_modal={() =>
                this.setState({
                  showDeleteModal: false,
                  deleteSavedId: "",
                })
              }
              delete_it={() => this.deleteSave()}
            />
          </>
        )}
      </>
    );
  }
}

export default withRouter(Favourite);
