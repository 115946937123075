import axios from "axios";
import toast from "./toast";

axios.defaults.headers["Access-Control-Allow-Origin"] = "*";

axios.defaults.headers["Accept"] = "*/*";
axios.defaults.headers["Accept-Language"] = "en";
axios.defaults.headers["Content-Language"] = "en-US";

const instance = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URI,
});

instance.interceptors.request.use(
  (config) => {
    const token = window.location.pathname.includes("/admin")
      ? localStorage.getItem("adminToken")
      : localStorage.getItem("token");
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add an interceptor to handle response errors
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 429:
          toast.error("You have been rate limited!");
          console.error("Too Many Requests (HTTP 429):", error);
          break;
        // case 403:
        //   toast.error(error.response.data.message);
        //   console.log(error);
        //   break;
        case 401: // Handling Unauthorized access
          const isAdminPath = window.location.pathname.includes("/admin");
          // Using environment variable for non-admin login URL
          const apiUri = process.env.REACT_APP_REST_API_URI;
          const loginPath = isAdminPath ? "/admin/login" : `${apiUri}/login`;
          toast.error("Session expired. Please login again.");
          // Redirect for admin or append login for API URI
          if (isAdminPath) {
            window.location.href = loginPath;
          } else {
            // Assuming you might need to handle cross-origin navigation differently
            window.location.href = loginPath; // Adjust if needed for handling non-admin login
          }
          break;
        default:
          // Handle other errors
          console.error("An error occurred:", error);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
