import * as React from "react";
import {
  adminGetModels,
  deletePricingModel,
  getPricingModels,
  postPricingModel,
  putPricingModel,
} from "../../../api/rest-apis";
import Spin from "../../../helpers/spin";
import { toastHelper } from "../../../helpers/toast";
import FreeTrailPricing from "../components/FreeTrailPricing";
import AdminHeader from "../components/header";

interface PricingModelProps {}

interface ModelPrice {
  _id?: string;
  model: string;
  input_token_price: number;
  output_token_price: number;
}

interface PricingModelState {
  modelList: string[];
  loading: boolean;
  prices: ModelPrice[];
}

class PricingModel extends React.Component<
  PricingModelProps,
  PricingModelState
> {
  state = {
    modelList: [],
    model: "",
    input_token_price: 0.01,
    loading: false,
    prices: [],
  };

  async componentDidMount(skipGetModels = false) {
    try {
      if (skipGetModels) {
        const pricingModels: any = await getPricingModels();
        this.setState({
          prices: pricingModels.pricingModels,
        });
        return;
      }
      const response: any = await adminGetModels();
      const pricingModels: any = await getPricingModels();
      this.setState({
        modelList: response.data,
        prices: pricingModels.pricingModels,
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="admin">
        <AdminHeader></AdminHeader>

        <div className="edit-character">
          <div className="title">Pricing Model</div>

          {this.state.prices.map((price: ModelPrice, index: number) => (
            <div className="flex items-center gap-8">
              <div className="bookish-input-group mt-7">
                <p className="bookish-input-label">
                  Model <span className="required">*</span>
                </p>
                <select
                  className="bookish-select-box"
                  value={price.model}
                  onChange={(e) =>
                    this.handleInputChange("model", e.target.value, index)
                  }
                >
                  {this.state.modelList.map((model: any, index) => (
                    <option key={index} value={model.id}>
                      {model.id}
                    </option>
                  ))}
                </select>
              </div>

              <div className="bookish-input-group mt-5">
                <p className="bookish-input-label">
                  Input token price (per million tokens){" "}
                  <span className="required">*</span>
                </p>
                <input
                  type="number"
                  className="bookish-input-field"
                  value={price.input_token_price}
                  onChange={(e) =>
                    this.handleInputChange(
                      "input_token_price",
                      e.target.value,
                      index
                    )
                  }
                />
              </div>

              <div className="bookish-input-group mt-5">
                <p className="bookish-input-label">
                  Output token price (per million tokens){" "}
                  <span className="required">*</span>
                </p>
                <input
                  type="number"
                  className="bookish-input-field"
                  value={price.output_token_price}
                  onChange={(e) =>
                    this.handleInputChange(
                      "output_token_price",
                      e.target.value,
                      index
                    )
                  }
                />
              </div>

              <div className="bookish-input-group mt-5">
                <div className="flex  cursor-pointer">
                  <img
                    className="h-full"
                    src="/assets/images/delete-red.svg"
                    alt=""
                    onClick={() => this.deletePricingModel(index)}
                  />
                </div>
              </div>
            </div>
          ))}

          <div className="flex justify-start">
            <div className="bookish-input-group mt-4 create-character-btn">
              <button
                className="bookish-primary-btn"
                onClick={() => this.handleSubmit()}
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <div className={"flex justify-center items-center"}>
                    <Spin></Spin>
                    <span style={{ color: "#fff" }}>Processing...</span>
                  </div>
                )}

                {!this.state.loading && (
                  <span style={{ color: "#fff" }}>Update</span>
                )}
              </button>
            </div>

            <div className="bookish-input-group ml-auto mt-4 create-character-btn">
              <button
                className="bookish-secondary-btn"
                onClick={() => this.addAField()}
                disabled={this.state.loading}
              >
                Add a new price field
              </button>
            </div>
          </div>
          <FreeTrailPricing></FreeTrailPricing>
        </div>
      </div>
    );
  }

  async deletePricingModel(index: number) {
    try {
      const prices: ModelPrice[] = [...this.state.prices];
      if (prices[index]._id) {
        const _id: any = prices[index]._id;
        deletePricingModel(typeof _id === "string" ? _id : "");
      }
      prices.splice(index, 1);
      this.setState({ prices });
    } catch (error) {
      console.error(error);
    }
  }

  async addAField() {
    const prices: ModelPrice[] = [...this.state.prices];
    const price: ModelPrice = {
      model: "gpt-4-1106-vision-preview",
      input_token_price: 0.01,
      output_token_price: 0.03,
    };
    prices.push(price);
    this.setState({ prices });

    try {
      await postPricingModel(price);
      this.componentDidMount(true); // We have to refresh it to get _id that will be help for updating.
    } catch (error) {
      console.error(error);
    }
  }

  async handleSubmit() {
    try {
      this.setState({ loading: true });
      const prices: ModelPrice[] = [...this.state.prices];

      for (let index = 0; index < prices.length; index++) {
        const price: ModelPrice = prices[index];
        await putPricingModel(price);
      }
      toastHelper.success("Updated successfully");
      this.setState({ loading: false });
    } catch (error) {
      toastHelper.error("Couldn't update");
      this.setState({ loading: false });
      return;
    }
  }

  handleInputChange = (field: string, value: any, index: number) => {
    const prices: ModelPrice[] = [...this.state.prices];
    const price = { ...prices[index] };
    if (field === "model") {
      price.model = value;
    } else if (
      field === "input_token_price" ||
      field === "output_token_price"
    ) {
      price[field] = value;
    }
    prices[index] = price;
    this.setState({ prices });
  };
}

export default PricingModel;
