import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import withRouter from "../../../helpers/withRouter";

interface AdminHeaderProps {
  location: any;
  navigate: any;
}

interface AdminHeaderState {}

class AdminHeader extends React.Component<AdminHeaderProps, AdminHeaderState> {
  state = {};

  logout() {
    localStorage.removeItem("adminToken");
    this.props.navigate("/admin/login");
  }
  render() {
    const { location } = this.props;

    const isActive = (path: any) => {
      // Check if the current location matches the provided path
      return location.pathname === path;
    };

    return (
      <div className="admin-header">
        <Link to="/admin">
          <div className="logo">
            <img src="/assets/images/logo.png" alt="" />
          </div>
        </Link>

        <Link to="/admin">
          <h1> Admin Dashboard</h1>
        </Link>

        <div className="relative inline-block text-left mr-4 ml-auto">
          <NavLink
            className={
              isActive("/admin/users")
                ? "font-medium ml-5 admin-header-active"
                : "font-medium ml-5"
            }
            to={"/admin/users"}
          >
            Users
          </NavLink>

          <NavLink
            className={
              isActive("/admin/booster")
                ? "font-medium ml-5 admin-header-active"
                : "font-medium ml-5"
            }
            to={"/admin/booster"}
          >
            Booster Quanity
          </NavLink>

          <NavLink
            className={
              isActive("/admin/pricing-model")
                ? "font-medium ml-5 admin-header-active"
                : "font-medium ml-5"
            }
            to={"/admin/pricing-model"}
          >
            Pricing Model
          </NavLink>
        </div>
        <div
          className="logout flex items-center p-1 mr-3 cursor-pointer"
          style={{
            border: "2px solid #D14444",
            color: "#D14444 !important",
            borderRadius: "5px",
          }}
          onClick={() => this.logout()}
        >
          <img className="h-[2rem]" src="/assets/images/logout.svg" alt="" />
          <p style={{ color: "#D14444", fontWeight: 600 }}>Logout</p>
        </div>
      </div>
    );
  }
}

export default withRouter(AdminHeader);
