import React from "react";
import withRouter from "../../helpers/withRouter";
import CharacterAnalytics from "./components/character-analytics";
import AdminHeader from "./components/header";
import ListCharacter from "./components/list-characters";

interface AdminHomeProps {
  navigate: any;
}

interface AdminHomeState {}

class AdminHome extends React.Component<AdminHomeProps, AdminHomeState> {
  state = {};

  constructor(props: AdminHomeProps) {
    super(props);
  }

  componentDidMount() {
    if (!localStorage.getItem("adminToken")) {
      setTimeout(() => this.props.navigate("login"), 500);
    }
  }

  render() {
    return (
      <div className="admin">
        <AdminHeader></AdminHeader>

        <ListCharacter></ListCharacter>

        <CharacterAnalytics></CharacterAnalytics>
      </div>
    );
  }
}

export default withRouter(AdminHome);
