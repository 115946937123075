import * as React from "react";
import { postSupport } from "../../api/rest-apis";
import Header from "../../components/header";
import SideMenu from "../../components/sideMenu";
import Spin from "../../helpers/spin";
import { toastHelper } from "../../helpers/toast";

interface SupportProps {}

interface SupportState {}

class Support extends React.Component<SupportProps, SupportState> {
  state = {
    loading: false,
    name: localStorage.getItem("user") || "",
    email: localStorage.getItem("email") || "",
    message: "",
  };

  async handleSubmit() {
    this.setState({ loading: true });

    if (!this.state.name || this.state.name.length === 0) {
      toastHelper.error("Name is required!");
      return this.setState({ loading: false });
    }

    if (
      !this.state.email ||
      this.state.email.length === 0 ||
      !this.state.email.includes("@")
    ) {
      toastHelper.error("E-mail is required!");
      return this.setState({ loading: false });
    }

    if (!this.state.message || this.state.message.length === 0) {
      toastHelper.error("Message is required!");
      return this.setState({ loading: false });
    }
    try {
      await postSupport({
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
      });

      toastHelper.success("Thanks, We will get back to you in 24h.");
      this.setState({ loading: false });
    } catch (error) {
      toastHelper.error("Something wrong with the request.");
      console.error(error);
    }
  }

  render() {
    return (
      <>
        <div className="home-container">
          <SideMenu></SideMenu>
          <div className="main-view">
            <Header></Header>
            <div className="md:container lg:w-11/12 mx-auto mt-8">
              <p className="text-2xl">Get support / give feedback</p>

              <p
                style={{
                  background: "#F65371",
                  color: "#fff",
                  borderRadius: "1rem",
                  width: "23rem",
                  fontSize: ".89rem",
                }}
                className="p-3 mt-4"
              >
                You can also email us at <br />
                <a
                  href="mailto:support@bookishboyfriend.com"
                  style={{ fontWeight: "600" }}
                >
                  support@bookishboyfriend.com
                </a>{" "}
              </p>

              <div className="bookish-input-group mt-4">
                <p className="bookish-input-label">
                  Name <span className="required">*</span>
                </p>
                <input
                  type="text"
                  className="bookish-input-field"
                  name="firstName"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                  required
                />
              </div>

              <div className="bookish-input-group mt-4">
                <p className="bookish-input-label">
                  E-mail <span className="required">*</span>
                </p>
                <input
                  type="text"
                  className="bookish-input-field"
                  name="firstName"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  required
                />
              </div>

              <div className="bookish-input-group mt-5">
                <p className="bookish-input-label">
                  Message <span className="required">*</span>
                </p>
                <textarea
                  className="bookish-input-field"
                  value={this.state.message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                  required
                />
              </div>

              <div className="bookish-input-group mt-4">
                <button
                  className="bookish-primary-btn"
                  onClick={() => this.handleSubmit()}
                >
                  {this.state.loading && (
                    <div className={"flex justify-center"}>
                      <Spin></Spin>
                      <span style={{ color: "#fff" }}>Processing...</span>
                    </div>
                  )}

                  {!this.state.loading && (
                    <span style={{ color: "#fff" }}>SUBMIT</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Support;
