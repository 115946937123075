import * as React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getSessionToken } from "../api/rest-apis";

interface CallBackProps {
  uuid: string;
  navigate: any;
}

interface CallBackState {}

class CallBack extends React.Component<CallBackProps, CallBackState> {
  state = {};

  async componentDidMount() {
    if (this.props.uuid) {
      try {
        const response: any = await getSessionToken(this.props.uuid);
        localStorage.setItem("token", response.token);
        localStorage.setItem(
          "user",
          `${response.firstName} ${response.lastName ? response.lastName : ""}`
        );
        localStorage.setItem("email", response.email);
        localStorage.setItem("user_id", response.user_id);
      } catch (error) {}

      setTimeout(() => this.props.navigate("../"), 1000);
    }
  }
  render() {
    return <></>;
  }
}

const withRouter = (WrappedComponent: any) => (props: any) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uuid = searchParams.get("uuid");

  const navigate = useNavigate();

  return <WrappedComponent uuid={uuid} navigate={navigate} {...props} />;
};

export default withRouter(CallBack);
