import React from "react";
import { Link } from "react-router-dom";

interface ConfirmFreeTrailProps {
  open: boolean;
  setOpen: any;
  reverseFunction: any;
}

interface ConfirmFreeTrailState {
  shouldRender: boolean;
}

class ConfirmFreeTrail extends React.Component<
  ConfirmFreeTrailProps,
  ConfirmFreeTrailState
> {
  constructor(props: ConfirmFreeTrailProps) {
    super(props);
    this.state = {
      shouldRender: true,
    };
  }

  setOpen = (open: boolean) => {
    if (open) {
      this.setState({ shouldRender: true }, () => {
        // Ensure the component is in the DOM before starting the animation
        setTimeout(() => this.props.setOpen(true), 10);
      });
    } else {
      this.props.setOpen(false);
      // Wait for the animation to finish before removing the modal from the DOM
      setTimeout(() => this.setState({ shouldRender: false }), 300);
    }
  };

  renderModal() {
    const { open } = this.props;
    return (
      <div
        className={`fixed inset-0 px-2 z-10 overflow-hidden flex items-center justify-center transition-opacity duration-300 ${
          open ? "opacity-100" : "opacity-0"
        }`}
        onClick={() => this.props.setOpen(false)}
      >
        <div className="absolute inset-0 bg-gray-500 bg-opacity-75"></div>
        {/* Modal Content */}
        <div
          className={`bg-white rounded-md shadow-xl overflow-hidden max-w-md w-full sm:w-96 md:w-1/2 lg:w-2/3 xl:w-1/2 z-50 transition-all duration-300 ${
            open ? "scale-100" : "scale-75"
          }`}
          onClick={(e) => e.stopPropagation()} // Prevent click from closing modal
        >
          {/* Modal Header */}
          <div className=" text-white px-4 py-2 flex justify-between items-center bg-primary">
            <h2 className="text-lg font-semibold text-white">Important!</h2>
          </div>
          {/* Modal Body */}
          <div className="p-4">
            <p>Do you want the free trail for Plus ?</p>
          </div>
          {/* Modal Footer */}

          <div className="flex justify-end border-t p-4 flex-wrap">
            <button
              onClick={() => {
                this.props.reverseFunction(false);
                this.setOpen(false);
              }}
              className="px-3 py-1 bg-secondary rounded-md"
              style={{ color: "#444" }}
            >
              Direct to Subscription
            </button>
            <button
              onClick={() => {
                this.props.reverseFunction(true);
                this.setOpen(false);
              }}
              className="mt-3 lg:mt-0 ml-0 lg:ml-3 px-3 py-1 bg-primary text-white rounded-md"
            >
              Yes I want the free trail
            </button>
          </div>

          {/* Close Modal */}
          <button
            onClick={() => this.setOpen(false)}
            className="absolute -top-0 -right-0 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white z-10"
          >
            ✗
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { shouldRender } = this.state;
    return (
      <div className="flex justify-center items-center h-screen">
        {shouldRender && this.renderModal()}
      </div>
    );
  }
}

export default ConfirmFreeTrail;
