import React from "react";
import withRouter from "../../../../helpers/withRouter";

interface MobileChatsSavedHistoryProps {
  closeModal: any;
  navigate: any;
  id: any;
  showHistory: any;
  history: any;
  checkSaved: any;
  deleteHistory: any;
}

interface MobileChatsSavedHistoryState {}

class MobileChatsSavedHistory extends React.Component<
  MobileChatsSavedHistoryProps,
  MobileChatsSavedHistoryState
> {
  state = {};

  constructor(props: MobileChatsSavedHistoryProps) {
    super(props);
  }

  render() {
    return (
      <>
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        ></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <div className="relative m-auto p-5 rounded-lg bg-white text-left shadow-xl transition-all max-w-md">
              {/* Title */}
              <div className="text-center mb-4">
                <h2 className="flex items-center text-xl font-semibold">
                  Chat history
                </h2>
              </div>

              <div className="space-y-4">
                <div className="border p-4 rounded-md">
                  <h3 className="font-semibold">History</h3>
                  <div className="flex-grow overflow-auto">
                    {this.props.showHistory &&
                      this.props.history.map((value: any, index: number) => (
                        <div className="flex">
                          <div
                            key={index}
                            className="sample-prompt border-b border-gray-200 py-2 px-4 cursor-pointer"
                            onClick={() => {
                              this.props.navigate(
                                `/chat/${this.props.id}/c/${value.conversation_id}`
                              );
                              this.props.checkSaved();
                              this.props.closeModal();
                            }}
                          >
                            {value.user_question}
                          </div>
                          <div
                            className="ml-auto flex align-center cursor-pointer"
                            onClick={() =>
                              this.props.deleteHistory({
                                showDeleteModal: true,
                                deleteConversationId: value.conversation_id,
                              })
                            }
                          >
                            <img
                              src="/assets/images/delete-red.svg"
                              alt=""
                              className="h-5 m-auto"
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Close Modal */}
              <button
                onClick={() => this.props.closeModal()}
                className="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
              >
                ✗
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(MobileChatsSavedHistory);
