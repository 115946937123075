import * as React from "react";
import AdminHeader from "../components/header";

interface RevenueProps {}

interface RevenueState {}

class Revenue extends React.Component<RevenueProps, RevenueState> {
  state = {};

  sampleData = Array.from({ length: 8 }, (_, index) => {
    // Generate data for each point
    const today = new Date(); // Get today's date
    const date = new Date(today.getTime() - index * 24 * 60 * 60 * 1000); // Subtract days from today
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;

    const sales = Math.round(Math.random() * 80) + 30;
    const apiCost = Math.round(Math.random() * 2) + 10;
    const profit = sales - apiCost;

    return { date: formattedDate, sales, apiCost, profit };
  });

  render() {
    return (
      <div className="admin">
        <AdminHeader></AdminHeader>
        <div className="edit-character">
          <div className="title">Revenue</div>
          <div className="sub-title mt-3">Sales, API Cost and Profit</div>

          <table className="my-table" style={{ textAlign: "left" }}>
            <thead className="mt-4">
              <tr>
                <th>Date</th>
                <th>Sales</th>
                <th>API Cost</th>
                <th>Net Profit</th>
              </tr>
            </thead>
            <tbody>
              {this.sampleData.map((value, index) => (
                <tr key={index} className="table-row mt-4">
                  <td>{value.date}</td>

                  <td>
                    <span className="table-status">${value.sales}</span>
                  </td>
                  <td>${value.apiCost}</td>
                  <td>${value.profit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Revenue;
