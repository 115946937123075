const ageGroups = [
  { id: "18 - 25", label: "18 - 25" },
  { id: "26 - 29", label: "26 - 29" },
  { id: "30 - 39", label: "30 - 39" },
  { id: "40 - 49", label: "40 - 49" },
  { id: "50 - 59", label: "50 - 59" },
  { id: "60 - 69", label: "60 - 69" },
  { id: "70 and over", label: "70 and over" },
];

export default ageGroups;
