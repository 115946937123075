import React from "react";
import { purchaseChatPointBooster } from "../api/rest-apis";
import Spin from "../helpers/spin";

import "./css/chatpoint-booster.css";

interface ChatpointBoosterProps {
  closeModal: any;
}

interface ChatpointBoosterState {}

class ChatpointBooster extends React.Component<
  ChatpointBoosterProps,
  ChatpointBoosterState
> {
  state = {
    loading_booster_1: false,
    loading_booster_2: false,
    loading_booster_3: false,
    loading_booster_4: false,
  };

  constructor(props: ChatpointBoosterProps) {
    super(props);
  }

  render() {
    return (
      <>
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        ></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <div className="relative m-auto p-5 rounded-lg bg-white text-left shadow-xl transition-all max-w-md">
              {/* Title */}
              <div className="text-center mb-4">
                <h2 className="flex items-center text-xl font-semibold">
                  Choose Your ChatPoint Booster
                  <img className="ml-4" src="/assets/images/boost.svg" alt="" />
                </h2>
              </div>

              {/* Booster Options */}
              <div className="space-y-4">
                {/* 75 ChatPoint Boosters */}
                <div className="border p-4 rounded-md">
                  <h3 className="font-semibold">75 ChatPoint Boosters</h3>

                  <ul className="list-disc mt-2">
                    <li>
                      Great for the <strong>Basic</strong> tier where each
                      ChatPoint goes a long way.
                    </li>
                  </ul>
                  <div
                    className="mt-4 py-4 w-full text-center boost-btn-secondary"
                    onClick={() =>
                      this.purchasChatPointBooster(
                        process.env.REACT_APP_CHATPOINT_BOOSTER_75_PRICE_ID,
                        0
                      )
                    }
                  >
                    {this.state.loading_booster_1 ? (
                      <div className={"flex justify-center items-center"}>
                        <Spin></Spin>
                        <span>Processing...</span>
                      </div>
                    ) : (
                      <span>Boost for $4.99</span>
                    )}
                  </div>
                </div>

                {/* 175 ChatPoint Boosters */}
                <div className="border p-4 rounded-md">
                  <h3 className="font-semibold">165 ChatPoint Boosters</h3>

                  <ul className="list-disc mt-2">
                    <li className="pt-2">
                      Good for the <strong>Plus</strong> tier.
                    </li>
                  </ul>
                  <div
                    className="mt-4 py-4 w-full text-center boost-btn-secondary"
                    onClick={() =>
                      this.purchasChatPointBooster(
                        process.env.REACT_APP_CHATPOINT_BOOSTER_175_PRICE_ID,
                        1
                      )
                    }
                  >
                    {this.state.loading_booster_2 ? (
                      <div className={"flex justify-center items-center"}>
                        <Spin></Spin>
                        <span>Processing...</span>
                      </div>
                    ) : (
                      <span>Boost for $9.99</span>
                    )}
                  </div>
                </div>

                {/* 550 ChatPoint Boosters */}
                <div
                  className="border p-4 pr-2 rounded-md"
                  style={{
                    border: "3px solid var(--primary-color)",
                    borderRadius: "10px",
                  }}
                >
                  <h3 className="font-semibold flex justify-start items-center">
                    530 ChatPoint Boosters{" "}
                    <span
                      className="ml-auto"
                      style={{
                        border: "2px solid var(--primary-color)",
                        borderRadius: "5px",
                        color: "#fff",
                        background: "var(--primary-color)",
                        padding: ".3rem .3rem",
                        fontSize: ".9rem",
                      }}
                    >
                      Recommended
                    </span>
                  </h3>

                  <ul className="list-disc mt-2">
                    <li>
                      Good for the <strong>Premium</strong> tier where voice
                      uses a lot of ChatPoints.
                    </li>
                  </ul>

                  <ul className="list-disc mt-2">
                    <li>
                      Good for longer conversations in the <strong>Plus</strong>{" "}
                      tier where, the longer your conversation, the more
                      ChatPoints you use with each message.
                    </li>
                  </ul>

                  <div
                    className="mt-4 py-4 w-full text-center boost-btn"
                    onClick={() =>
                      this.purchasChatPointBooster(
                        process.env.REACT_APP_CHATPOINT_BOOSTER_550_PRICE_ID,
                        2
                      )
                    }
                  >
                    {this.state.loading_booster_3 ? (
                      <div className={"flex justify-center items-center"}>
                        <Spin></Spin>
                        <span>Processing...</span>
                      </div>
                    ) : (
                      <span>Boost for $29.99</span>
                    )}
                  </div>
                </div>

                {/* 1200 ChatPoint Boosters */}
                <div className="border p-4 rounded-md">
                  <h3 className="font-semibold">6000 ChatPoint Boosters</h3>

                  <ul className="list-disc mt-2">
                    <li>
                      {" "}
                      Best value because your ChatPoint Boosters will carry over
                      each month.
                    </li>
                  </ul>
                  <div
                    className="mt-4 py-4 w-full text-center boost-btn-secondary"
                    onClick={() =>
                      this.purchasChatPointBooster(
                        process.env.REACT_APP_CHATPOINT_BOOSTER_1200_PRICE_ID,

                        3
                      )
                    }
                  >
                    {this.state.loading_booster_4 ? (
                      <div className={"flex justify-center items-center"}>
                        <Spin></Spin>
                        <span>Processing...</span>
                      </div>
                    ) : (
                      <span>Boost for $299.99</span>
                    )}
                  </div>
                </div>
              </div>

              {/* Close Modal */}
              <button
                onClick={() => this.props.closeModal()}
                className="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
              >
                ✗
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
  async purchasChatPointBooster(price_id: string | undefined, index: number) {
    const loaders = [
      "loading_booster_1",
      "loading_booster_2",
      "loading_booster_3",
      "loading_booster_4",
    ];
    try {
      const update = { [loaders[index]]: true };

      this.setState(update);
      const response: any = await purchaseChatPointBooster(price_id);
      window.location.href = response.sessionUrl;
    } catch (error) {
      console.error(error);
      const update = { [loaders[index]]: false };
      this.setState(update);
    } finally {
      // this.setState({ loading: false, indexSubscribing: -1 });
    }
  }
}

export default ChatpointBooster;
