import React from "react";
import {
  adminBlockToggle,
  getUserInfoById,
  getUserTokenUsage,
} from "../../../api/rest-apis";
import Spin from "../../../helpers/spin";
import { toastHelper } from "../../../helpers/toast";
import withRouter from "../../../helpers/withRouter";
import AdminHeader from "../components/header";
import TokenUsageComponent from "../components/TokenUsageComponent";
import UserTokenUsage from "../components/user-token-usage";

interface UserProps {
  id: string;
}

interface UserState {}

class User extends React.Component<UserProps, UserState> {
  state = {
    userTokenUsage: [],
    userTokenUsageModal: false,
    invoices: [],
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    age: "",
    country: "",
    city: "",
    plan: "",
    interests: [],
    favoriteStories: [],
    blockUserloading: false,
    userStatus: "",
    whereDidYouHear: "",
    stripeCustomerUrl: "",
    purchases: [],
  };

  close_modal() {
    this.setState({ userTokenUsageModal: false });
  }

  async blockUserToggle() {
    this.setState({ blockUserloading: true });
    try {
      await adminBlockToggle({
        user_id: this.props.id,
        status: this.state.userStatus === "active" ? "blocked" : "active",
      });

      await this.componentDidMount();
      toastHelper.success(
        this.state.userStatus === "active" ? "User blocked." : "User unblocked."
      );
    } catch (error) {
    } finally {
      this.setState({ blockUserloading: false });
    }
  }

  async componentDidMount() {
    try {
      const userTokenUsage: any = await getUserTokenUsage(this.props.id);
      // Calculate totals
      const totals = userTokenUsage.reduce(
        (acc: any, obj: any) => {
          acc.prompt_tokens += obj.prompt_tokens;
          acc.completion_tokens += obj.completion_tokens;
          acc.credit_used_for_prompt_tokens +=
            obj.credit_used_for_prompt_tokens;
          acc.credit_used_for_completion_tokens +=
            obj.credit_used_for_completion_tokens;
          return acc;
        },
        {
          prompt_tokens: 0,
          completion_tokens: 0,
          credit_used_for_prompt_tokens: 0,
          credit_used_for_completion_tokens: 0,
        }
      );

      // Create the "total" object with necessary fields
      const totalObject = {
        _id: "",
        user_id: "",
        character_id: "",
        assistant_id: "",
        conversation_id: "",
        model: "",
        user_prompt: "",
        prompt_tokens: totals.prompt_tokens,
        completion_tokens: totals.completion_tokens,
        credit_used_for_prompt_tokens: parseFloat(
          totals.credit_used_for_prompt_tokens.toFixed(4)
        ),
        credit_used_for_completion_tokens: parseFloat(
          totals.credit_used_for_completion_tokens.toFixed(4)
        ),
        createdAt: new Date(),
        updatedAt: new Date(),
        __v: 0,
        plan_used: "",
        character_name: "Total",
        assistant_group: "",
      };

      // Add the "total" object to the original array
      userTokenUsage.push(totalObject);

      this.setState({ userTokenUsage });
    } catch (error) {}
    try {
      const user: any = await getUserInfoById(this.props.id);

      let formattedInterests = Object.keys(user.interests).filter(
        (value) => user.interests[value] === true
      );

      let formattedStories = Object.keys(user.favoriteStories).filter(
        (value) => user.favoriteStories[value] === true
      );

      const formatValue = (value: string) => {
        // Convert "selfHelp" to "Self Help"
        return (
          value.charAt(0).toUpperCase() +
          value
            .slice(1)
            .replace(/([A-Z])/g, " $1")
            .trim()
        );
      };

      formattedInterests = formattedInterests.map((value) =>
        formatValue(value)
      );
      formattedStories = formattedStories.map((value) => formatValue(value));

      this.setState({
        firstName: user.firstName,
        lastName: user.lastName,
        userStatus: user.user_status,
        email: user.email,
        gender: user.gender,
        age: user.age,
        country: user.country,
        city: user.city ? user.city : null,
        plan: user.plan.charAt(0).toUpperCase() + user.plan.slice(1),
        invoices: user.invoicesList,
        purchases: user.purchases,
        interests: formattedInterests,
        favoriteStories: formattedStories,
        whereDidYouHear: user.whereDidYouHear,
        stripeCustomerUrl: user.stripeCustomerUrl,
      });

      setTimeout(() => console.log(this.state), 300);
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="admin">
        <AdminHeader></AdminHeader>
        {this.state.userTokenUsageModal && (
          <UserTokenUsage
            close_modal={() => this.close_modal()}
            data={this.state.userTokenUsage}
          >
            {this.state.userTokenUsage.length > 0 && (
              <TokenUsageComponent
                data={this.state.userTokenUsage}
              ></TokenUsageComponent>
            )}
          </UserTokenUsage>
        )}

        <div className="edit-character">
          <div className="title">
            User: {this.state.firstName + " " + this.state.lastName}
          </div>
          <div className="sub-title mt-3">
            Plan: <span className="table-status">{this.state.plan}</span>
          </div>
          <div className="flex flex-wrap gap-4">
            <div className="bookish-input-group mt-4">
              <a href={this.state.stripeCustomerUrl} target="_blank">
                {" "}
                <button className="bookish-normal-btn">
                  Open Customer In Stripe
                </button>
              </a>
            </div>

            <div className="bookish-input-group mt-4">
              <button
                className="bookish-normal-btn"
                onClick={() => this.setState({ userTokenUsageModal: true })}
              >
                View Token Usage
              </button>
            </div>

            <div className="bookish-input-group mt-4">
              <button
                className="bookish-normal-btn"
                style={{ color: "#fff", background: "var(--primary-color)" }}
                onClick={() => this.blockUserToggle()}
              >
                {this.state.blockUserloading && (
                  <div className={"flex justify-center"}>
                    <Spin></Spin>
                    <span style={{ color: "#fff" }}>Processing...</span>
                  </div>
                )}

                {!this.state.blockUserloading &&
                  (this.state.userStatus === "active"
                    ? "Block user"
                    : "Unblock user")}
              </button>
            </div>
          </div>

          <div className="bookish-input-group mt-8">
            <p className="bookish-input-label" style={{ fontSize: "1.3rem" }}>
              Invoice History <span className="required">*</span>
            </p>
            <p className="bookish-input-label">
              Showing invoices within the past 12 months
            </p>
          </div>

          <table className="my-table" style={{ textAlign: "left" }}>
            <thead className="mt-4">
              <tr>
                <th>INVOICE</th>
                <th>STATUS</th>
                <th>AMOUNT</th>
                <th>CREATED</th>
              </tr>
            </thead>
            <tbody>
              {this.state.invoices.map((value: any, index) => (
                <tr key={index} className="table-row mt-4">
                  <td>
                    <a
                      href={value.hosted_invoice_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      View Invoice
                    </a>
                  </td>
                  <td>
                    <span className="table-status">
                      {value.status.charAt(0).toUpperCase() +
                        value.status.slice(1)}
                    </span>
                  </td>
                  <td>${value.amount / 100}</td>
                  <td>{value.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {this.state.purchases.length > 0 && (
            <>
              <div className="bookish-input-group mt-8">
                <p
                  className="bookish-input-label"
                  style={{ fontSize: "1.3rem" }}
                >
                  ChatPoint Booster Purchase History{" "}
                  <span className="required">*</span>
                </p>
              </div>

              <table className="my-table" style={{ textAlign: "left" }}>
                <thead className="mt-4">
                  <tr>
                    <th>DESCRIPTION</th>
                    <th>AMOUNT</th>
                    <th>PURCHASE DATE</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.purchases.map((value: any, index) => (
                    <tr key={index} className="table-row mt-4">
                      <td>{value.description}</td>
                      <td>
                        <span className="table-status">
                          ${value.amount_paid}
                        </span>
                      </td>

                      <td>{value.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          <div className="flex flex-wrap mt-8 lg:w-9/12">
            <div>
              <div className="title">User Information</div>

              <div className="bookish-input-group mt-4">
                <p className="bookish-input-label">
                  Email <span className="required">*</span>
                </p>
                <input
                  type="text"
                  className="bookish-input-field"
                  name="firstName"
                  value={this.state.email}
                  disabled
                />
              </div>
              <div className="bookish-input-group mt-4">
                <p className="bookish-input-label">
                  First Name <span className="required">*</span>
                </p>
                <input
                  type="text"
                  className="bookish-input-field"
                  name="firstName"
                  value={this.state.firstName}
                  disabled
                />
              </div>
              <div className="bookish-input-group mt-4">
                <p className="bookish-input-label">
                  Last Name <span className="required">*</span>
                </p>
                <input
                  type="text"
                  className="bookish-input-field"
                  name="firstName"
                  value={this.state.lastName}
                  disabled
                />
              </div>

              <div className="bookish-input-group mt-4">
                <p className="bookish-input-label">
                  Gender <span className="required">*</span>
                </p>
                <input
                  type="text"
                  className="bookish-input-field"
                  name="firstName"
                  value={this.state.gender}
                  disabled
                />
              </div>

              <div className="bookish-input-group mt-4">
                <p className="bookish-input-label">
                  Age <span className="required">*</span>
                </p>
                <input
                  type="text"
                  className="bookish-input-field"
                  name="firstName"
                  value={this.state.age}
                  disabled
                />
              </div>
            </div>
            <div className="lg:ml-auto">
              {this.state.interests.length > 0 && (
                <div className="bookish-input-group mt-4">
                  <p className="bookish-input-label">
                    Interest <span className="required">*</span>
                  </p>
                  <div className="bookish-input-multi-label  flex flex-wrap">
                    {this.state.interests.map((value) => (
                      <span className="card">{value}</span>
                    ))}
                  </div>
                </div>
              )}

              {this.state.favoriteStories.length > 0 && (
                <div className="bookish-input-group mt-4">
                  <p className="bookish-input-label">
                    Stories <span className="required">*</span>
                  </p>
                  <div className="bookish-input-multi-label flex flex-wrap">
                    {this.state.favoriteStories.map((value) => (
                      <span className="card">{value}</span>
                    ))}
                  </div>
                </div>
              )}

              <div className="bookish-input-group mt-4">
                <p className="bookish-input-label">
                  Country/City <span className="required">*</span>
                </p>
                <div className="bookish-input-multi-label">
                  <span className="card">{this.state.country}</span>

                  <span className="card">{this.state.city}</span>
                </div>
              </div>

              <div className="bookish-input-group mt-4">
                <p className="bookish-input-label">
                  Where did you hear about us?{" "}
                  <span className="required">*</span>
                </p>
                <input
                  type="text"
                  className="bookish-input-field"
                  name="firstName"
                  value={this.state.whereDidYouHear}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(User);
