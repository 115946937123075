import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  FiChevronLeft,
  FiChevronRight,
  FiArrowUp,
  FiArrowDown,
} from "react-icons/fi";

interface TokenUsage {
  _id: string;
  user_id: string;
  character_id: string;
  assistant_id: string;
  conversation_id: string;
  model: string;
  user_prompt: string;
  prompt_tokens: number;
  completion_tokens: number;
  credit_used_for_prompt_tokens: number;
  credit_used_for_completion_tokens: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
  plan_used: string;
  character_name: string;
  assistant_group: string;
}

interface Props {
  data: TokenUsage[];
}

const TokenUsageComponent: React.FC<Props> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<string>("character_name");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const sortedData = useMemo(() => {
    const sortableItems = [...data];
    sortableItems.sort((a: any, b: any) => {
      if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
    return sortableItems;
  }, [data, sortField, sortDirection]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleSortChange = (field: string) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSortChange("character_name")}
                style={{ cursor: "pointer" }}
              >
                Character Name{" "}
                {sortField === "character_name" &&
                  (sortDirection === "asc" ? <FiArrowUp /> : <FiArrowDown />)}
              </TableCell>
              <TableCell
                align="right"
                onClick={() => handleSortChange("plan_used")}
                style={{ cursor: "pointer" }}
              >
                Plan Used{" "}
                {sortField === "plan_used" &&
                  (sortDirection === "asc" ? <FiArrowUp /> : <FiArrowDown />)}
              </TableCell>
              {/* Implement sorting for other columns as needed */}
              <TableCell
                align="right"
                onClick={() => handleSortChange("prompt_tokens")}
                style={{ cursor: "pointer" }}
              >
                Prompt Tokens{" "}
                {sortField === "plan_used" &&
                  (sortDirection === "asc" ? <FiArrowUp /> : <FiArrowDown />)}
              </TableCell>
              <TableCell
                align="right"
                onClick={() => handleSortChange("completion_tokens")}
                style={{ cursor: "pointer" }}
              >
                Completion Tokens{" "}
                {sortField === "completion_tokens" &&
                  (sortDirection === "asc" ? <FiArrowUp /> : <FiArrowDown />)}
              </TableCell>

              <TableCell
                align="right"
                onClick={() =>
                  handleSortChange("credit_used_for_prompt_tokens")
                }
                style={{ cursor: "pointer" }}
              >
                Credit Used (Prompt){" "}
                {sortField === "credit_used_for_prompt_tokens" &&
                  (sortDirection === "asc" ? <FiArrowUp /> : <FiArrowDown />)}
              </TableCell>
              <TableCell
                align="right"
                onClick={() =>
                  handleSortChange("credit_used_for_completion_tokens")
                }
                style={{ cursor: "pointer" }}
              >
                Credit Used (Completion){" "}
                {sortField === "credit_used_for_completion_tokens" &&
                  (sortDirection === "asc" ? <FiArrowUp /> : <FiArrowDown />)}
              </TableCell>
              <TableCell
                align="right"
                onClick={() => handleSortChange("user_prompt")}
                style={{ cursor: "pointer" }}
              >
                User Prompt{" "}
                {sortField === "user_prompt" &&
                  (sortDirection === "asc" ? <FiArrowUp /> : <FiArrowDown />)}
              </TableCell>
              <TableCell
                align="right"
                onClick={() => handleSortChange("createdAt")}
                style={{ cursor: "pointer" }}
              >
                Date{" "}
                {sortField === "createdAt" &&
                  (sortDirection === "asc" ? <FiArrowUp /> : <FiArrowDown />)}
              </TableCell>
              <TableCell
                align="right"
                onClick={() => handleSortChange("assistant_group")}
                style={{ cursor: "pointer" }}
              >
                Assistant Group{" "}
                {sortField === "assistant_group" &&
                  (sortDirection === "asc" ? <FiArrowUp /> : <FiArrowDown />)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows.map((row) => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  {row.character_name}
                </TableCell>
                <TableCell align="right">{row.plan_used}</TableCell>
                <TableCell align="right">{row.prompt_tokens}</TableCell>
                <TableCell align="right">{row.completion_tokens}</TableCell>
                <TableCell align="right">
                  {row.credit_used_for_prompt_tokens}
                </TableCell>
                <TableCell align="right">
                  {row.credit_used_for_completion_tokens}
                </TableCell>
                <TableCell align="right">
                  <Tooltip title={row.user_prompt} placement="top">
                    <span>{row.user_prompt}</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  {new Date(row.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell align="right">{row.assistant_group}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-between items-center mt-4">
        <button
          className="flex items-center px-4 py-2 text-sm text-gray-500 bg-white rounded-md shadow hover:bg-gray-100"
          disabled={currentPage <= 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <FiChevronLeft className="mr-2" /> Prev
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="flex items-center px-4 py-2 text-sm text-gray-500 bg-white rounded-md shadow hover:bg-gray-100"
          disabled={currentPage >= totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next <FiChevronRight className="ml-2" />
        </button>
      </div>
    </>
  );
};

export default TokenUsageComponent;
