import React from "react";

type Props = {};
type State = {};

class RedirectToHome extends React.Component<Props, State> {
  componentDidMount() {
    // Set item in local storage
    localStorage.setItem("UX_RT", "true");

    // Redirect to home after 500ms
    setTimeout(() => {
      window.location.href = window.location.origin;
    }, 500);
  }

  render() {
    return <div>Processing... Please wait.</div>;
  }
}

export default RedirectToHome;
