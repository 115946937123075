import React from "react";
import { postCharacter } from "../../../api/rest-apis";
import toast from "../../../helpers/toast";
import withRouter from "../../../helpers/withRouter";
import { Character } from "../../../interfaces/rest-api";
import CharacterForm from "../components/character-form";
import AdminHeader from "../components/header";

interface EditCharacterProps {
  navigate: any;
}

interface EditCharacterState {}

class EditCharacter extends React.Component<
  EditCharacterProps,
  EditCharacterState
> {
  createCharacterAPICall = async (form: Character) => {
    const myToast = toast.loading("Creating character ...");
    try {
      const response: any = await postCharacter(form);
      toast.success("Character Created!", myToast);
      this.props.navigate("/admin/edit-character/" + response.result._id);
    } catch (error) {
      toast.error("Couldn't create character", myToast);
    }
  };

  render() {
    return (
      <div className="admin">
        <AdminHeader></AdminHeader>
        <div className="edit-character">
          <CharacterForm
            create={true}
            reverseFunction={this.createCharacterAPICall}
            clearForm={true}
          ></CharacterForm>
        </div>
      </div>
    );
  }
}

export default withRouter(EditCharacter);
