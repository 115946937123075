import React, { useState, useEffect } from "react";
import AdminHeader from "../components/header";
import Spin from "../../../helpers/spin";
import { adminGetBoosters, postBoosterQuantity } from "../../../api/rest-apis"; // Import the update API
import toast from "../../../helpers/toast";

type Booster = {
  quantity: number;
  unlimited: boolean;
  name: string;
};

type BoosterState = {
  [key: string]: Booster;
};

const BoosterManager: React.FC = () => {
  const [boosters, setBoosters] = useState<BoosterState>({
    booster75: {
      quantity: 5,
      unlimited: false,
      name: "75 ChatPoint Boosters available for the month",
    },
    booster165: {
      quantity: 10,
      unlimited: false,
      name: "165 ChatPoint Boosters available for the month",
    },
    booster530: {
      quantity: 10,
      unlimited: false,
      name: "530 ChatPoint Boosters available for the month",
    },
    booster6000: {
      quantity: 1,
      unlimited: false,
      name: "6000 ChatPoint Boosters available for the month",
    },
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBoosters = async () => {
      try {
        const response: any = await adminGetBoosters();
        console.log(response);

        if (response.booster && response.booster.length > 0) {
          const updatedBoosters: BoosterState = {};
          response.booster.forEach((booster: any) => {
            updatedBoosters[booster.id] = {
              quantity: booster.available.total,
              unlimited: booster.unlimited,
              name: booster.name,
            };
          });
          setBoosters(updatedBoosters);
        }
      } catch (error) {
        console.error("Failed to fetch boosters:", error);
      }
    };

    fetchBoosters();
  }, []);

  const handleToggleUnlimited = (boosterKey: string) => {
    setBoosters((prevBoosters) => ({
      ...prevBoosters,
      [boosterKey]: {
        ...prevBoosters[boosterKey],
        unlimited: !prevBoosters[boosterKey].unlimited,
      },
    }));
  };

  const handleQuantityChange = (boosterKey: string, newQuantity: number) => {
    setBoosters((prevBoosters) => ({
      ...prevBoosters,
      [boosterKey]: {
        ...prevBoosters[boosterKey],
        quantity: newQuantity,
      },
    }));
  };

  const handleUpdate = async () => {
    setLoading(true);

    try {
      const boostersToUpdate = Object.keys(boosters).map((key) => ({
        id: key,
        name: boosters[key].name,
        available: { total: boosters[key].quantity },
        unlimited: boosters[key].unlimited,
      }));

      const response = await postBoosterQuantity(boostersToUpdate);
      console.log("Update response:", response);
      toast.success("Updated successsfully!");

      // Optionally, you can update the local state if the response contains updated data
      setLoading(false);
    } catch (error) {
      console.error("Failed to update boosters:", error);
      setLoading(false);
    }
  };

  return (
    <div className="admin">
      <AdminHeader></AdminHeader>

      <div className="edit-character">
        <div className="title">ChatPoint Booster Quantity</div>
        <div className="space-y-4 w-full md:w-1/2 mt-4">
          {Object.entries(boosters).map(([key, booster]) => (
            <div
              key={key}
              className="flex items-center justify-between p-4 bg-white rounded-lg shadow-md"
            >
              <label className="mr-4 text-lg font-medium text-gray-700">
                {booster.name}
              </label>
              <input
                type="number"
                value={booster.unlimited ? "" : booster.quantity}
                onChange={(e) =>
                  handleQuantityChange(key, Number(e.target.value))
                }
                disabled={booster.unlimited}
                className="p-2 border rounded w-24 text-center bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <div className="flex items-center">
                <span className="mr-2 text-lg font-medium text-gray-700">
                  Unlimited
                </span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={booster.unlimited}
                    onChange={() => handleToggleUnlimited(key)}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-500 dark:bg-gray-700 peer-checked:bg-blue-600 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                </label>
              </div>
            </div>
          ))}
        </div>

        <div className="bookish-input-group mt-4 create-character-btn">
          <button
            className="bookish-primary-btn"
            onClick={handleUpdate}
            disabled={loading}
          >
            {loading ? (
              <div className={"flex justify-center items-center"}>
                <Spin></Spin>
                <span style={{ color: "#fff" }}>Processing...</span>
              </div>
            ) : (
              <span style={{ color: "#fff" }}>Update</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BoosterManager;
