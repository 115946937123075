import * as React from "react";
import {
  getFreeTrailPricing,
  updateFreeTrailPricing,
} from "../../../api/rest-apis";
import Spin from "../../../helpers/spin";
import { toastHelper } from "../../../helpers/toast";

interface FreeTrailPricingProps {}

interface FreeTrailPricingState {
  basicPlanCredit: number;
  plusPlanCredit: number;
  loading: boolean;
}
class FreeTrailPricing extends React.Component<
  FreeTrailPricingProps,
  FreeTrailPricingState
> {
  state: FreeTrailPricingState = {
    basicPlanCredit: 0,
    plusPlanCredit: 0,
    loading: false,
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const data: any = await getFreeTrailPricing();
      this.setState({
        basicPlanCredit: data.basic_plan_credit_amount,
        plusPlanCredit: data.plus_plan_free_trial_credit_amount,
        loading: false,
      });
    } catch (error) {
      toastHelper.error("Couldn't fetch the free trial pricing");
      console.error(error);
      this.setState({ loading: false });
    }
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState({
      [name]: parseFloat(value),
    } as Pick<FreeTrailPricingState, keyof {}>);
  };

  async handleSubmit() {
    this.setState({ loading: true });
    const { basicPlanCredit, plusPlanCredit } = this.state;

    try {
      await updateFreeTrailPricing({
        basic_plan_credit_amount: basicPlanCredit,
        plus_plan_free_trial_credit_amount: plusPlanCredit,
      });
      toastHelper.success("Updated successfully");
      this.setState({ loading: false });
    } catch (error) {
      toastHelper.error("Couldn't update");
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className="mx-auto mt-12">
        <div className="title">Free Trail Limit</div>
        <div className="mt-6 mb-6 max-w-[23rem]">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="basicPlanCredit"
          >
            Basic Plan Credit Amount
          </label>
          <input
            type="number"
            step="0.01"
            id="basicPlanCredit"
            name="basicPlanCredit"
            value={this.state.basicPlanCredit}
            onChange={this.handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-6 max-w-[23rem]">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="plusPlanCredit"
          >
            Plus Plan Free Trial Credit Amount
          </label>
          <input
            type="number"
            step="0.01"
            id="plusPlanCredit"
            name="plusPlanCredit"
            value={this.state.plusPlanCredit}
            onChange={this.handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="bookish-input-group mt-4 create-character-btn">
          <button
            className="bookish-primary-btn"
            onClick={() => this.handleSubmit()}
            disabled={this.state.loading}
          >
            {this.state.loading && (
              <div className={"flex justify-center items-center"}>
                <Spin />
                <span style={{ color: "#fff" }}>Processing...</span>
              </div>
            )}

            {!this.state.loading && (
              <span style={{ color: "#fff" }}>Update Free Trail Limit</span>
            )}
          </button>
        </div>
      </div>
    );
  }
}

export default FreeTrailPricing;
