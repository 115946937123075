const stories = [
  { key: "m_m", name: "M/M" },
  { key: "m_m_f", name: "M/M/F" },
  { key: "m_f_m", name: "M/F/M" },
  { key: "m_f", name: "M/F" },
  { key: "interracial", name: "Interracial" },
  { key: "romanticErotica", name: "Romantic Erotica" },
  { key: "daddyStories", name: "Daddy Stories" },
  { key: "mommyStories", name: "Mommy Stories" },
  { key: "stepSiblingStories", name: "Step Sibling Stories" },
  { key: "wolfShifter", name: "Wolf Shifter" },
  { key: "bdsm", name: "BDSM" },
  { key: "newAdult", name: "New Adult" },
  { key: "romanticSuspense", name: "Romantic Suspense" },
  { key: "romanticComedy", name: "Romantic Comedy" },
  { key: "suspenseThrillerMystery", name: "Suspense Thriller Mystery" },
  { key: "audiobooks", name: "Audiobooks" },
  { key: "selfHelp", name: "Self Help" },
];

export default stories;
