// conversationReducer.js
const initialState = {
  conversation_id: "null",
};

const conversationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_CONVERSATION_ID":
      return { ...state, conversation_id: action.payload };
    default:
      return state;
  }
};

export default conversationReducer;
