import React from "react";
import Header from "../../components/header";
import SideMenu from "../../components/sideMenu";
import Personal from "./components/setting/personal";
import Subscription from "./components/setting/subscription";
import "./css/setting.css";

interface SettingProps {}

interface SettingState {}

class Setting extends React.Component<SettingProps, SettingState> {
  state = { personalTab: false, subscriptionTab: true };
  constructor(props: SettingProps) {
    super(props);
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab() {
    this.setState({
      personalTab: !this.state.personalTab,
      subscriptionTab: !this.state.subscriptionTab,
    });
  }
  render() {
    return (
      <>
        <div className="home-container">
          <SideMenu></SideMenu>
          <div className="main-view">
            <Header></Header>

            <div className="w-11/12 lg:w-11/12 mx-auto mt-5 tab-nav-container lg:flex ">
              <div
                className={
                  this.state.subscriptionTab
                    ? "tab w-11/12 lg:w-3/6  active-tab"
                    : "tab w-11/12 lg:w-3/6"
                }
                onClick={this.toggleTab}
              >
                Subscription Management
              </div>
              <div
                className={
                  this.state.personalTab
                    ? "tab w-11/12 lg:w-3/6 active-tab"
                    : "tab w-11/12 lg:w-3/6"
                }
                onClick={this.toggleTab}
              >
                Personal Information & Security
              </div>
            </div>

            <div className="tab-content-container w-11/12 m-auto">
              {this.state.personalTab && (
                <div className="mt-12">
                  <Personal></Personal>
                </div>
              )}
              {this.state.subscriptionTab && (
                <div className="mt-12">
                  <Subscription></Subscription>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Setting;
