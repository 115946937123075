import React from "react";
import {
  deleteConversation,
  getPreviousConversations,
} from "../../api/rest-apis";
import Header from "../../components/header";
import SideMenu from "../../components/sideMenu";
import { toastHelper } from "../../helpers/toast";
import withRouter from "../../helpers/withRouter";
import DeleteModalHelper from "../../helpers/delete-modal";
import Spin from "../../helpers/spin";

interface ChatsProps {
  navigate: any;
}

interface ChatsState {
  deleteConversationId: string;
  showDeleteModal: boolean;
  today: any[];
  conversationsLast7Days: any[];
  conversationsLast30Days: any[];
  loading: boolean;
}

class Chats extends React.Component<ChatsProps, ChatsState> {
  state = {
    today: [],
    conversationsLast7Days: [],
    conversationsLast30Days: [],
    deleteConversationId: "",
    showDeleteModal: false,
    loading: true,
  };
  constructor(props: ChatsProps) {
    super(props);
  }

  async componentDidMount() {
    this.getConversations();
  }

  async getConversations() {
    try {
      const response: any = await getPreviousConversations(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      this.setState({
        today: response.today,
        conversationsLast7Days: response.conversationsLast7Days,
        conversationsLast30Days: response.conversationsLast30Days,
        loading: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async deleteConversation() {
    const id = toastHelper.loading("Deleting ...");
    try {
      await deleteConversation(this.state.deleteConversationId);

      toastHelper.success("Conversation have been deleted!", id);
      this.setState({ showDeleteModal: false, deleteConversationId: "" });
      setTimeout(async () => await this.getConversations(), 500);
    } catch (error) {
      toastHelper.error("Error on deleting conversation", id);
      this.setState({ showDeleteModal: false, deleteConversationId: "" });
    }
  }
  render() {
    return (
      <>
        <div className="home-container">
          <SideMenu></SideMenu>
          <div className="main-view lg:ml-6">
            <Header></Header>

            <div className="favourites mt-8">
              <p className="favourites-title">Previous Chats</p>
              {this.state.loading && (
                <div className="flex justify-center mt-8">
                  <Spin></Spin>
                </div>
              )}
              {this.state.today.length == 0 &&
                this.state.conversationsLast7Days.length == 0 &&
                this.state.conversationsLast30Days.length === 0 &&
                !this.state.loading && (
                  <p style={{ color: "var(--primary-color)" }}>
                    It looks like you haven't had any conversations with
                    characters yet.
                  </p>
                )}
              {this.state.today.length > 0 && <p>Today</p>}
              {this.state.today.map((conversation: any, index) => (
                <div className="favourite-card flex">
                  <img
                    className="character-img"
                    src={conversation.character_profile}
                    alt=""
                    onClick={() =>
                      this.props.navigate(
                        `/chat/${conversation.character_id}/c/${conversation._id}`
                      )
                    }
                  />
                  <div
                    className="flex flex-col justify-center"
                    onClick={() =>
                      this.props.navigate(
                        `/chat/${conversation.character_id}/c/${conversation._id}`
                      )
                    }
                  >
                    <p className="favourite-title ml-3">
                      {conversation.title.length > 32
                        ? conversation.title.slice(0, 32)
                        : conversation.title}
                    </p>
                    <p className="favourite-character ml-3">
                      {"  (" + conversation.character_name + ")"}
                    </p>
                  </div>
                  <div
                    className="ml-auto flex align-center"
                    onClick={() =>
                      this.setState({
                        showDeleteModal: true,
                        deleteConversationId: conversation._id,
                      })
                    }
                  >
                    <img src="/assets/images/delete-red.svg" alt="" />
                  </div>
                </div>
              ))}

              {this.state.conversationsLast7Days.length > 0 && (
                <p className="mt-4">Last 7 Days</p>
              )}

              {this.state.conversationsLast7Days.map(
                (conversation: any, index) => (
                  <div className="favourite-card flex">
                    <img
                      className="character-img"
                      src={conversation.character_profile}
                      alt=""
                      onClick={() =>
                        this.props.navigate(
                          `/chat/${conversation.character_id}/c/${conversation._id}`
                        )
                      }
                    />
                    <div
                      className="flex flex-col justify-center"
                      onClick={() =>
                        this.props.navigate(
                          `/chat/${conversation.character_id}/c/${conversation._id}`
                        )
                      }
                    >
                      <p className="favourite-title ml-3">
                        {conversation.title.length > 32
                          ? conversation.title.slice(0, 32)
                          : conversation.title}
                      </p>
                      <p className="favourite-character ml-3">
                        {"  (" + conversation.character_name + ")"}
                      </p>
                    </div>
                    <div
                      className="ml-auto flex align-center"
                      onClick={() =>
                        this.setState({
                          showDeleteModal: true,
                          deleteConversationId: conversation._id,
                        })
                      }
                    >
                      <img src="/assets/images/delete-red.svg" alt="" />
                    </div>
                  </div>
                )
              )}

              {this.state.conversationsLast30Days.length > 0 && (
                <p className="mt-4">Last 30 Days</p>
              )}
              {this.state.conversationsLast30Days.map(
                (conversation: any, index) => (
                  <div className="favourite-card flex">
                    <img
                      className="character-img"
                      src={conversation.character_profile}
                      alt=""
                      onClick={() =>
                        this.props.navigate(
                          `/chat/${conversation.character_id}/c/${conversation._id}`
                        )
                      }
                    />
                    <div
                      className="flex flex-col justify-center"
                      onClick={() =>
                        this.props.navigate(
                          `/chat/${conversation.character_id}/c/${conversation._id}`
                        )
                      }
                    >
                      <p className="favourite-title ml-3">
                        {conversation.title.length > 32
                          ? conversation.title.slice(0, 32)
                          : conversation.title}
                      </p>
                      <p className="favourite-character ml-3">
                        {"  (" + conversation.character_name + ")"}
                      </p>
                    </div>
                    <div
                      className="ml-auto flex align-center"
                      onClick={() =>
                        this.setState({
                          showDeleteModal: true,
                          deleteConversationId: conversation._id,
                        })
                      }
                    >
                      <img src="/assets/images/delete-red.svg" alt="" />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        {this.state.showDeleteModal && (
          <>
            <DeleteModalHelper
              title={"Delete Conversation"}
              message={"Are you sure you want to delete the conversation ? "}
              close_modal={() =>
                this.setState({
                  showDeleteModal: false,
                  deleteConversationId: "",
                })
              }
              delete_it={() => this.deleteConversation()}
            />
          </>
        )}
      </>
    );
  }
}

export default withRouter(Chats);
