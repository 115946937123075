import * as React from "react";
import AudioPlayerT from "../helpers/audioPlayer";
import { Character } from "../interfaces/rest-api";
import isEqual from "lodash/isEqual";

interface ChatHistoryProps {
  history: any;
  character: Character;
  loading: boolean;
  isVoiceEnabled: boolean;
  showBothVoiceText: boolean;
  userPlan: string;
  conversation_id: string;
  newChatReceived: boolean;
}

interface ChatHistoryState {
  voiceResponses: any[];
  newChatReceived: boolean;
}

class ChatHistory extends React.Component<ChatHistoryProps, ChatHistoryState> {
  state = { voiceResponses: [], newChatReceived: false };

  componentDidMount() {
    this.updateVoiceSetting();
  }

  componentDidUpdate(prevProps: ChatHistoryProps) {
    // Check if showBothVoiceText has changed
    if (prevProps.showBothVoiceText !== this.props.showBothVoiceText) {
      this.updateVoiceSetting();
    }

    if (!isEqual(prevProps.history, this.props.history)) {
      this.updateVoiceSetting();
    }
  }

  updateVoiceSetting() {
    // Code to execute when showBothVoiceText changes
    const arr: any = [];
    this.props.history.forEach((message: any, index: number) => {
      const role = message.right ? "assistant" : "user";
      if (role === "assistant") {
        if (index === 0) {
          arr.push(true);
        } else {
          // If it's not the first message but the role is "assistant", push false
          arr.push(this.props.showBothVoiceText);
        }
      } else {
        // If the role is not "assistant", push false
        arr.push(false);
      }
    });

    // Set the feature upcommming messages also showBothVoiceText

    for (let index = 0; index < 10; index++) {
      arr.push(this.props.showBothVoiceText);
    }

    this.setState({ voiceResponses: arr });
  }

  voiceResponsesToggle(index: number): void {
    const arr: any = [...this.state.voiceResponses];
    arr[index] = !arr[index];
    this.setState({ voiceResponses: arr });
  }

  render() {
    return (
      <div style={{ paddingBottom: "7rem" }}>
        {this.props.history.map((value: any, index: number) => (
          <div
            className="chat-message flex gap-4 mt-4"
            style={{
              marginLeft: value.right ? "0" : "4rem",
              textAlign: "start",
            }}
            key={index}
          >
            {value.right ? (
              <img
                src={this.props.character.character_profile}
                className="character-photo w-1/4"
                style={{}}
              ></img>
            ) : (
              <div
                className="person-photo w-1/4"
                style={{
                  background: value.right
                    ? "var(--secondary-color)"
                    : "var(--primary-color)",
                }}
              ></div>
            )}

            <div>
              {value.right ? (
                <p className="chat-account">
                  {this.props.character.character_name}

                  {this.props.userPlan === "Premium" &&
                    this.props.isVoiceEnabled &&
                    index > 0 && (
                      <span
                        className="chat-view-text-response"
                        onClick={() => this.voiceResponsesToggle(index)}
                      >
                        {this.state.voiceResponses[index]
                          ? "Hide text"
                          : "View text response"}
                      </span>
                    )}
                </p>
              ) : (
                <p className="chat-account">{localStorage.getItem("user")}</p>
              )}

              {this.props.userPlan === "Premium" &&
              this.props.isVoiceEnabled ? (
                <>
                  {this.state.voiceResponses[index] && (
                    <p
                      className={`chat-box  ${
                        value.right ? "assistant" : "user"
                      }  my-1`}
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {value.message}
                    </p>
                  )}

                  {index === 0 ||
                    (!value.right && (
                      <p
                        className={`chat-box  ${
                          value.right ? "assistant" : "user"
                        }  my-1`}
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {value.message}
                      </p>
                    ))}
                </>
              ) : (
                <p
                  className={`chat-box  ${
                    value.right ? "assistant" : "user"
                  }  my-1`}
                  style={{ whiteSpace: "pre-line" }}
                >
                  {value.message}
                </p>
              )}

              {this.props.userPlan === "Premium" &&
                this.props.isVoiceEnabled &&
                value.right &&
                index > 0 && (
                  <p
                    className={`chat-box user my-1`}
                    style={{ whiteSpace: "pre-line" }}
                  >
                    <AudioPlayerT
                      autoPlay={
                        this.props.history.length === index + 1 &&
                        this.props.newChatReceived
                      }
                      audioUrl={
                        `${process.env.REACT_APP_REST_API_URI}/api/v1/audio?text=` +
                        value.message +
                        `&elevenlabs_voice_id=${
                          this.props.character.elevenlabs_voice_id
                        }&conversation_id=${
                          this.props.conversation_id
                        }&user_id=${localStorage.getItem("user_id")}`
                      }
                      key={`${this.props.conversation_id}-${index}`}
                    ></AudioPlayerT>
                  </p>
                )}
            </div>
          </div>
        ))}

        {this.props.loading && (
          <div
            className="chat-message  animate-pulse flex gap-4 mt-4"
            style={{
              marginLeft: "0",
              textAlign: "start",
            }}
          >
            <div
              className="person-photo w-1/4"
              style={{
                background: "var(--primary-color)",
              }}
            ></div>
            <div>
              <div
                className="h-3 rounded"
                style={{
                  background: "var(--primary-color)",
                  width: "4rem",
                }}
              ></div>
              <p
                className={`chat-box user my-1`}
                style={{
                  color: "var(--primary-color)",
                  background: "var(--primary-color)",
                }}
              >
                Loading ...
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ChatHistory;
