import React, { Component } from "react";
import { Chart as ChartJS, ChartData, ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register the components you use.
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend
);

interface Utils {
  months: ({ count }: { count: number }) => string[];
  numbers: ({
    count,
    min,
    max,
  }: {
    count: number;
    min: number;
    max: number;
  }) => number[];
  CHART_COLORS: { [key: string]: ChartColor };
  transparentize: (color: ChartColor, opacity: number) => string;
  namedColor: (index: number) => ChartColor;
  rand: (min: number, max: number) => number;
}

// Implementing the Utils object
const Utils: Utils = {
  months: ({ count }) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return count ? monthNames.slice(0, count) : monthNames;
  },
  numbers: ({ count, min, max }) =>
    Array.from({ length: count }, () =>
      Math.floor(Math.random() * (max - min + 1) + min)
    ),
  CHART_COLORS: {
    red: "rgb(255, 99, 132)",
    blue: "rgb(54, 162, 235)",
    yellow: "rgb(225, 159, 64)",
    yellow_2: "rgb(255, 205, 86)",
    blue_water: "rgb(75, 192, 192)",
    indigo: "rgb(153, 102, 255)",
    default: "rgb(201, 203, 207)",
  },
  transparentize: (color, opacity) => {
    // Assuming the input is in the format 'rgb(x, y, z)'
    const [r, g, b] = color.match(/\d+/g)?.map(Number) || [0, 0, 0];
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  },
  namedColor: (index) => {
    const colors = Object.values(Utils.CHART_COLORS);
    return colors[index % colors.length];
  },
  rand: (min, max) => Math.floor(Math.random() * (max - min + 1) + min),
};

type ChartColor = string; // Define a type for chart colors

// Updated Utils interface remains the same
// Assuming Utils is defined somewhere with correct types

// Define an interface for the component's props
interface SingleCharacterChartProps {
  yAxisValues: number[][]; // Array of arrays of numbers for each dataset
  xAxisLabels: string[]; // Array of strings for the X axis (e.g., months, days, weeks)
  datasetLabels: string[]; // Array of strings for dataset labels
}

// Define an interface for the component's state
interface SingleCharacterChartState {
  data: ChartData<"line">;
  options: ChartOptions<"line">;
}

class SingleCharacterChart extends Component<
  SingleCharacterChartProps,
  SingleCharacterChartState
> {
  constructor(props: SingleCharacterChartProps) {
    super(props);
    this.state = this.initializeChartData();
  }

  initializeChartData(): SingleCharacterChartState {
    const { yAxisValues, xAxisLabels, datasetLabels } = this.props;

    const datasets = yAxisValues.map((dataSet, index) => ({
      label: datasetLabels[index],
      data: dataSet,
      borderColor: Utils.namedColor(index), // Utilizing namedColor for dynamic color assignment
      backgroundColor: Utils.transparentize(Utils.namedColor(index), 0.5),
    }));
    return {
      data: {
        labels: xAxisLabels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Chart.js Line Chart Example",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return `${value} Tokens`; // Customize this part as per the Y-axis label requirement
              },
            },
          },
        },
      },
    };
  }

  render() {
    const { data, options } = this.state;

    return (
      <div className="single-character-chart">
        <Line data={data} options={options} />
      </div>
    );
  }
}

export default SingleCharacterChart;
