import * as React from "react";
import { Navigate } from "react-router-dom";
import { adminLogin } from "../../../api/rest-apis";
import Spin from "../../../helpers/spin";
import toast from "../../../helpers/toast";
import { AdminLoginRequest } from "../../../interfaces/rest-api";

interface AdminLoginProps {}

interface AdminLoginState {
  email: string;
  password: string;
  btnSubmit: boolean;
  userLoggedIn: boolean;
}

class AdminLogin extends React.Component<AdminLoginProps, AdminLoginState> {
  constructor(props: AdminLoginProps) {
    super(props);
    this.state = {
      email: "",
      password: "",
      btnSubmit: false,
      userLoggedIn: false,
    };
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    this.setState({ btnSubmit: true });

    // Create a payload object from the state
    const payload: AdminLoginRequest = {
      email_address: this.state.email,
      password: this.state.password,
    };

    try {
      // Call the adminLogin function with the payload
      const response: any = await adminLogin(payload);
      localStorage.setItem("adminToken", response.token);
      toast.success("You have logged in successsfully!");
      this.setState({ userLoggedIn: true });
    } catch (error: any) {
      // Handle the error
      toast.error(error.response.data.message);
    }

    this.setState({ btnSubmit: false });
  };

  render() {
    return (
      <>
        {this.state.userLoggedIn && <Navigate to="/admin" replace={true} />}
        <div className="container">
          <div>
            <div className="form-title">Admin Login</div>
            <form onSubmit={this.handleFormSubmit}>
              <div className="bookish-input-group">
                <p className="bookish-input-label">
                  Email Address <span className="required">*</span>
                </p>
                <input
                  type="text"
                  className="bookish-input-field"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="bookish-input-group">
                <p className="bookish-input-label">
                  Password <span className="required">*</span>
                </p>
                <input
                  type="password"
                  className="bookish-input-field"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="bookish-input-group mt-4">
                <button
                  type="submit"
                  className="bookish-primary-btn"
                  onClick={this.handleFormSubmit}
                  disabled={this.state.btnSubmit}
                >
                  {this.state.btnSubmit && (
                    <div className={"flex justify-center"}>
                      <Spin></Spin>
                      <span style={{ color: "#fff" }}>Processing...</span>
                    </div>
                  )}

                  {!this.state.btnSubmit && (
                    <span style={{ color: "#fff" }}>Login</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default AdminLogin;
