import React from "react";
import { Link } from "react-router-dom";
import { deleteCharacter, getCharacters } from "../../../api/rest-apis";
import { toastHelper } from "../../../helpers/toast";
import { Character } from "../../../interfaces/rest-api";
import DeleteModal from "./delete-modal";

interface ListCharacterProps {}

interface ListCharacterState {
  characters: Character[];
  deleteCharacterId: string;
  showDeleteModal: boolean;
}

class ListCharacter extends React.Component<
  ListCharacterProps,
  ListCharacterState
> {
  state = { characters: [], showDeleteModal: false, deleteCharacterId: "" };

  constructor(props: ListCharacterProps) {
    super(props);
    this.getCharacters();
    this.getCharacters = this.getCharacters.bind(this);
    this.deleteCharacter = this.deleteCharacter.bind(this);
  }

  async getCharacters() {
    try {
      const response: any = await getCharacters();
      this.setState({ characters: response.characters });
    } catch (error) {
      console.error(error);
    }
  }

  async deleteCharacter() {
    const id = toastHelper.loading("Deleting ...");
    try {
      await deleteCharacter(this.state.deleteCharacterId);

      toastHelper.success("Character have been deleted!", id);
      this.setState({ showDeleteModal: false, deleteCharacterId: "" });
      setTimeout(async () => await this.getCharacters(), 500);
    } catch (error) {
      toastHelper.error("Error on deleting assistant", id);
      this.setState({ showDeleteModal: false, deleteCharacterId: "" });
    }
  }

  render() {
    return (
      <>
        <div className="edit-character">
          <div className="title">Characters</div>
          <div className="bookish-input-group">
            <Link to="/admin/create-character">
              <button
                className="bookish-secondary-btn"
                style={{ textAlign: "start" }}
              >
                Create Character
              </button>
            </Link>
          </div>
          <div className="flex overflow-x-auto">
            <div className="inline-flex space-x-4">
              {this.state.characters.map((value: any, index: number) => (
                <div className="character-card" key={index}>
                  <img
                    className="character-image"
                    src={value.character_profile}
                    alt=""
                  />
                  <p
                    className="edit-character-desc"
                    dangerouslySetInnerHTML={{
                      __html: value.human_description,
                    }}
                  >
                    {/* {truncateParagraph(value.human_description)} */}
                  </p>
                  <p className="edit-character-title">{value.character_name}</p>
                  <div className="flex justify-center gap-5">
                    <Link to={"/admin/edit-character/" + value._id}>
                      <img
                        className="edit-icon"
                        src="assets/images/edit.svg"
                        alt=""
                      />
                    </Link>

                    <Link to={"/admin/character-analytics/" + value._id}>
                      <img
                        className="edit-icon"
                        src="assets/images/analytics.svg"
                        alt=""
                      />
                    </Link>

                    <button
                      onClick={() =>
                        this.setState({
                          showDeleteModal: true,
                          deleteCharacterId: value._id,
                        })
                      }
                    >
                      <img
                        className="edit-icon"
                        src="assets/images/delete.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {this.state.showDeleteModal && (
          <>
            {this.state.characters
              .filter(
                (value: any) => value._id === this.state.deleteCharacterId
              )
              .map((character: any) => (
                <DeleteModal
                  character_name={
                    character?.character_name || "Unknown Character"
                  }
                  close_modal={() =>
                    this.setState({
                      showDeleteModal: false,
                      deleteCharacterId: "",
                    })
                  }
                  delete_it={() => this.deleteCharacter()}
                />
              ))}
          </>
        )}
      </>
    );
  }
}

export default ListCharacter;
