import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header";

interface LoginProps {}

interface LoginState {}

class Login extends React.Component<LoginProps, LoginState> {
  state = {};
  render() {
    return (
      <div>
        <Header></Header>

        <div className="container">
          <div>
            <div className="form-title">Login</div>

            <div className="bookish-input-group">
              <p className="bookish-input-label">
                Email Address <span className="required">*</span>
              </p>
              <input type="text" className="bookish-input-field" />
            </div>

            <div className="bookish-input-group">
              <p className="bookish-input-label">
                Password <span className="required">*</span>
              </p>
              <input type="password" className="bookish-input-field" />
            </div>

            <div className="bookish-input-group mt-4">
              <button className="bookish-primary-btn">Login</button>
            </div>

            <div className="bookish-input-group">
              <Link to="/signup">
                <button className="bookish-secondary-btn">Create</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
