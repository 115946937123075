import * as React from "react";
import { getAccountInfo } from "../../../../api/rest-apis";

interface PersonalProps {}

interface PersonalState {}

class Personal extends React.Component<PersonalProps, PersonalState> {
  state = { firstName: "", lastName: "", email: "", provider: "" };

  componentDidMount() {
    this.getAccountInfo();
  }

  async getAccountInfo() {
    try {
      const response: any = await getAccountInfo();
      this.setState({
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email,
        provider:
          response.provider.charAt(0).toUpperCase() +
          response.provider.slice(1),
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <>
        <div className="bookish-input-group mt-4">
          <p className="bookish-input-label">
            Email <span className="required">*</span>
          </p>
          <input
            type="text"
            className="bookish-input-field"
            name="firstName"
            value={this.state.email}
            disabled
          />
        </div>
        <div className="bookish-input-group mt-4">
          <p className="bookish-input-label">
            First Name <span className="required">*</span>
          </p>
          <input
            type="text"
            className="bookish-input-field"
            name="firstName"
            value={this.state.firstName}
            disabled
          />
        </div>
        <div className="bookish-input-group mt-4">
          <p className="bookish-input-label">
            Last Name <span className="required">*</span>
          </p>
          <input
            type="text"
            className="bookish-input-field"
            name="firstName"
            value={this.state.lastName}
            disabled
          />
        </div>

        <div className="bookish-input-group mt-4">
          <p className="bookish-input-label">
            Sign In Option <span className="required">*</span>
          </p>
          <input
            type="text"
            className="bookish-input-field"
            name="firstName"
            value={this.state.provider}
            disabled
          />
        </div>

        {/* <div className="bookish-input-group mt-4">
          <button className="bookish-cancel-btn">Change password</button>
        </div> */}
      </>
    );
  }
}

export default Personal;
